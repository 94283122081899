<template>
  <div id="patientService">
    
   
        <img :class="windowWidth >= 1200? 'SliderStyle':'SliderStyleMobile'" alt="" src="@/assets/images/Patient/servicebanner.png" />
    
     <div style="height:48vh" v-if="windowWidth >= 1200">
     </div>

    <div class="vx-row">

      <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl">
        {{ $t("ServicesProvidedToOurPatients") }}
      </p>
    </div>

    
    <div class="ml-5 mr-5">
      
      <div class="ml-5 mr-5 mt-10" style="font-size: 20px">
        <checkpoint v-for="item in points" :data="item" :key="item.title" />
      </div>
    </div>
  </div>
</template>
<script>
import checkpoint from "../../components/check_point.vue";
export default {
  components: {
    checkpoint,
  },
  data() {
    return {
      EnglishLan:false,
      points: [
        { title: this.$i18n.t("OnlineAppointmentsOnlineConsultations") },
        {
          title: this.$i18n.t("FollowAppointmentsConsultations"),
        },
        {
          title: this.$i18n.t("SurgeryRequest"),
        },
        {
          title: this.$i18n.t("DoctorsVisitsDestination"),
        },
        {
          title: this.$i18n.t("PatientsHistoryMedicalService"),
        },
        {
          title: this.$i18n.t("TravelArrangementsPatients"),
        },
        {
          title: this.$i18n.t("TranslationServices"),
        },
        {
          title: this.$i18n.t("MedicalPackagesSurgeries"),
        },
        {
          title: this.$i18n.t("NewslettersHospitalDoctor"),
        },
      ],
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  computed:{
      windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  created(){
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

  },
    mounted(){
        window.onscroll = function() {myFunction()};
        
        function myFunction() {
           var navbar = document.getElementById("navbarStick");
        var sticky = navbar.offsetTop;
          if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
          } else {
            navbar.classList.remove("sticky");
          }
        }
  },
};
</script>
<style  >
#patientService .SliderStyleMobile{
width: 100%;
  z-index: 1;
  
}
#patientService .SliderStyle{
  position: absolute;
      top: 0px;
    z-index: 90;
    left: 89px;
  width: 90vw;
 max-height:49vh;
 object-fit: cover;
}
</style>